<template>
  <card-modal
    icon=""
    :title="$t('business.eft_items.manual.generate_action.title')"
    context="generateManualEft"
    @submit="submit"
    @cancel="cancel"
    cancel_text="business.eft_items.manual.generate_action.button_cancel"
    submit_text="business.eft_items.manual.generate_action.button_generate"
    data-test="eft-item--generate-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-select
          name="type"
          v-model="manualEft.type"
          v-validate="'required'"
          :error-messages="errors.collect('type')"
          data-vv-name="type"
          :items="manual_eft_types"
          item-text="name"
          item-value="value"
          :label="$t('business.eft_items.manual.generate_action.type') + ' *'"
          data-test="generate-eft--type"
        ></v-select>
        <v-text-field
          type="text"
          name="amount"
          append-icon="fas fa-dollar-sign"
          v-validate="'required|decimal:2|min_value:0.01|max_value:50000'"
          :error-messages="errors.collect('amount')"
          v-model="manualEft.amount"
          :label="$t('business.eft_items.manual.generate_action.amount') + ' *'"
          data-test="generate-eft--amount"
        ></v-text-field>
        <v-text-field
          type="text"
          name="reason"
          v-validate="'required'"
          :error-messages="errors.collect('reason')"
          v-model="manualEft.reason"
          :label="$t('business.eft_items.manual.generate_action.reason') + ' *'"
          data-test="generate-eft--reason"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'generate-manual-eft-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    business_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      manualEft: {},
      manual_eft_types: [
        // NOTE (SG) : REMOVE FOR NOW { name: this.$t('business.eft_items.manual.types.fees'), value: 'fees' },
        { name: this.$t('business.eft_items.manual.types.payment'), value: 'payment' },
        { name: this.$t('business.eft_items.manual.types.funding'), value: 'funding' },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.manualEft = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('manualEftCreated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.manualEft.business_id = this.business_id;

        await this.executeAction(
          {
            action: 'eft/generateManualEft',
            name: 'generateManualEft',
            success: this.success,
          },
          this.manualEft,
        );
      }
    },
  },
};
</script>
