<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('business.eft_items_table.date') }}</td>
          <td>{{ $t('business.eft_items_table.type') }}</td>
          <td>{{ $t('business.eft_items_table.bank_account') }}</td>
          <td align="right">{{ $t('business.eft_items_table.amount') }}</td>
          <td>{{ $t('business.eft_items_table.eft_report_file_number') }}</td>
          <td>{{ $t('business.eft_items_table.status') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('business.eft_items_table.date') }}:</h5>
            <span>{{ item.creation_date | formatShortDateTimeInverted }}</span>
          </td>
          <td>
            <h5>{{ $t('business.eft_items_table.type') }}:</h5>
            <span v-if="item.type === 'fees'">{{
              item.apply_taxes ? $t(`business.eft_items.types.${item.type}`) : $t(`business.eft_items.types.payment`)
            }}</span>
            <span v-else>{{ $t(`business.eft_items.types.${item.type}`) }}</span>
          </td>
          <td>
            <h5>{{ $t('business.eft_items_table.bank_account') }}:</h5>
            <span>{{ item.bank_account_number }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('business.eft_items_table.amount') }}:</h5>
            <span>{{ item.amount | currency | dollarSignI18n }}</span>
          </td>
          <td>
            <h5>{{ $t('business.eft_items_table.eft_report_file_number') }}:</h5>
            <span>{{ item.eft_report_file_number }}</span>
          </td>
          <td>
            <h5>{{ $t('business.eft_items_table.status') }}:</h5>
            <eft-item-status-view :status="item.status" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import EftItemStatusView from '../EftItemStatusView';

export default {
  name: 'business-eft-items-table',
  mixins: [actionErrorTrackable, security],
  components: { EftItemStatusView },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.searchPageNumber;
  },
  computed: {
    // ...mapGetters('business', ['searchPageCount', 'searchPageNumber']),
  },
  methods: {},
  watch: {},
};
</script>
